import './background.scss'
import React from 'react'
import Particles from 'react-particles-js'

const Background = () => (
  <Particles className={'background'} params={particlesConf} />
)

export default Background

const particlesConf = {
  'particles': {
    'number': {
      'value': 30,
      'density': {
        'enable': true,
        'value_area': 800,
      },
    },
    'color': {
      'value': '#f71735',
    },
    'shape': {
      'type': 'circle',
    },
    'opacity': {
      'value': 1,
      'random': true,
      'anim': {
        'enable': true,
        'speed': 1,
        'opacity_min': 0,
        'sync': false,
      },
    },
    'size': {
      'value': 5,
      'random': true,
      'anim': {
        'enable': false,
        'speed': 4,
        'size_min': 0.3,
        'sync': false,
      },
    },
    'line_linked': {
      'enable': false,
    },
    'move': {
      'enable': true,
      'speed': 1,
      'direction': 'none',
      'random': true,
      'straight': false,
      'out_mode': 'out',
      'bounce': false,
      'attract': {
        'enable': false,
        'rotateX': 600,
        'rotateY': 600,
      },
    },
  },
  'interactivity': {
    'detect_on': 'canvas',
    'events': {
      'onhover': {
        'enable': true,
        'mode': 'bubble',
      },
      'resize': true,
    },
    'modes': {
      'grab': {
        'distance': 400,
        'line_linked': {
          'opacity': 1,
        },
      },
      'bubble': {
        'distance': 250,
        'size': 0,
        'duration': 0.3,
        'opacity': 0,
        'speed': 10,
      },
    },
  },
  'retina_detect': true,
}

