import "./footer.scss"
import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer className={"footer"}>
    <div className={"footer__container"}>
      <ul className={"footer__menu"}>
        <li className={"footer__menuItem"}>
          <Link to={"/"} className={"footer__menuLink"}>
            COQOON
          </Link>
        </li>
        <li className={"footer__menuItem"}>|</li>
        <li className={"footer__menuItem"}>
          <Link to={"/legal-notice"} className={"footer__menuLink"}>
            Legal
          </Link>
        </li>
        <li className={"footer__menuItem"}>|</li>
        <li className={"footer__menuItem"}>
          <Link to={"/privacy-policy"} className={"footer__menuLink"}>
            Privacy
          </Link>
        </li>
      </ul>
      <div className={"footer__copyRight"}>
        © {new Date().getFullYear()} COQOON
      </div>
    </div>
  </footer>
)

export default Footer
