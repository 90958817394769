/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import './layout.scss'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Background from '../background'
import Footer from '../footer'
import SideBar from '../sideBar'

const Layout = ({ children, isMenuActive = false }) => (
  <div className={isMenuActive ? 'layout layout--shifted' : 'layout'}>
    <SideBar />
    <Background />
    <div className={'layout__contentContainer'}>
      <main>{children}</main>
      <Footer>
      </Footer>
    </div>
  </div>

)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = ({ page }) => {
  const { isMenuActive = false } = page
  return { isMenuActive }
}

export default connect(mapStateToProps)(Layout)
