import "./sideBar.scss"
import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "gatsby"

import * as PageActions from "../../state/reducers/page/actions"

const SideBar = function({ closeMenu, toggleMenu, isMenuActive }) {
  const onClickMenuLink = () => {
    setTimeout(toggleMenu, 1000)
  }

  return (
    <aside className={isMenuActive ? "sideBar sideBar--shifted" : "sideBar"}>
      <div className={"sideBar__container"}>
        <button
          className={"sideBar__button"}
          onClick={toggleMenu}
          name={"Toggle Menu"}
        >
          CQ
        </button>
        <button
          className={"sideBar__closeButton"}
          onClick={closeMenu}
          name={"Close Menu"}
        />
        <ul className={"sideBar__menu"}>
          <li className={"sideBar__menuItem"}>
            <Link
              to={"/"}
              className={"sideBar__menuLink"}
              onClick={onClickMenuLink}
            >
              COQOON
            </Link>
          </li>
          <li className={"sideBar__menuItem"}>
            <Link
              to={"/legal-notice"}
              className={"sideBar__menuLink"}
              onClick={onClickMenuLink}
            >
              Legal
            </Link>
          </li>
          <li className={"sideBar__menuItem"}>
            <Link
              to={"/privacy-policy"}
              className={"sideBar__menuLink"}
              onClick={onClickMenuLink}
            >
              Privacy
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  )
}

SideBar.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
}

SideBar.defaultProps = {}

const mapStateToProps = ({ page }) => {
  const { isMenuActive = false } = page
  return { isMenuActive }
}

const mapDispatchToProps = dispatch => {
  return {
    closeMenu: () => dispatch(PageActions.setMenuState(false)),
    toggleMenu: () => dispatch(PageActions.toggleMenuState()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar)
